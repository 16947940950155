<template>


  <div class="main-container">
    <ProductCarousel :slides="product.images" @product-carousel-click="openCarouselModal" />
    <ProductInfo :product="product" />
  </div>

</template>

<script>

import ProductCarousel from "@/components/ProductCarousel";
import ProductInfo from "@/components/ProductInfo";

import vClickOutside from "click-outside-vue3";
import json from "./list.json";
export default {
  name: "App",
  components: {
    ProductCarousel,
    ProductInfo,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      cartOpen: false,
      carouselModalOpen: false,
      imageClickedIndex: 0,
      cart: [],

      product: {},
      json,
    };
  },
  created() {
    const id = this.$route.params.id;

    this.product = json.find((e) => e.code == id);
  },

};
</script>

<style>
.logo-footer {
  width: 60px;
  margin-left: 10px;
}



.attribution {
  margin-top:2rem;
  font-size: 11px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
 
}

.attribution a {
  color: hsl(228, 45%, 44%);
}

@media (min-width: 1024px) {
  .main-container {
    display: flex;
    margin-top: calc(var(--header-height) + 3.5rem);
    padding: 7rem 18rem;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .carousel {
    flex-basis: 40%;
  }
}
</style>
