<template>
  <button class="app-button padding-y--small"
    :class="icon == 'checkmark.svg' ? 'app-button--green' : 'app-button--orange'">
    <slot></slot>
    <img v-if="icon" class="app-button__icon" :src="getImgUrl(icon)" :alt="alt" />
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default() {
        return "";
      },
    },
    alt: {
      type: String,
      default() {
        return "";
      },
    },
  },
  methods: {
    getImgUrl(pic) {
      return require(`../assets/images/${pic}`);
    },
  },
};
</script>

<style scoped>
.app-button {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.9rem;
  border-radius: var(--border-radius-size);
  padding: 1rem 2rem;
}

.app-button--green {
  background-color: var(--color-primary-green);
  color: var(--color-white);
}

.app-button--orange {
  background-color: #FF7D1A;
  color: var(--color-white);
}

.app-button--orange-box-shadow {
  box-shadow: 0 5px 20px 0 hsla(36, 100%, 55%, 0.418);
  text-align: left;
}

.app-button--green-box-shadow {
  box-shadow: 0 5px 20px 0 hsla(93, 100%, 55%, 0.418);
  text-align: left;
}
.app-button--orange:hover {
  background-color: var(--color-primary-orange);
}
.app-button--green:hover {
  background-color: var(--color-primary-green);
}
</style>
