<template>
  <div id="app" class="h-100">
    <AppHeader :cart-product-amount="cart.length" @cart-clicked="toggleCart" @open-menu="cartOpen = false" />
    <AppCart v-if="cartOpen" v-click-outside="onClickOutsideCart" :cart="cart"
      @cart-item-deleted="removeFromCart(id)" />
    <router-view />

    <teleport to="#modals">
      <CarouselModal v-if="carouselModalOpen" :slides="product.images" :opened-image-index="imageClickedIndex"
        @close-product-carousel-modal="carouselModalOpen = !carouselModalOpen" />
    </teleport>

    <div class="attribution">
      Powered by
       <img class="logo-footer" src="@/assets/images/logo.jpg" alt="1gate logo" />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppCart from "@/components/AppCart";
import CarouselModal from "@/components/Modals/CarouselModal";
import vClickOutside from "click-outside-vue3";

export default {
  components: {
    AppHeader,
    CarouselModal,
    AppCart,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      cartOpen: false,
      carouselModalOpen: false,
      imageClickedIndex: 0,
      cart: [],

      product: {},
    };
  },
  methods: {
    toggleCart() {
      this.cartOpen = !this.cartOpen;
    },

    onClickOutsideCart() {
      this.cartOpen = false;
    },

    addProductToCart(product, quantity) {
      if (quantity < 1) return;

      const item = this.cart.findIndex((x) => x.productId === product.id);

      // Means this product is already in the cart
      if (item !== -1) {
        this.cart[item].quantity += quantity;
      } else {
        const cartItem = {
          productId: product.id,
          name: product.name,
          mainThumbnail: product.mainThumbnail,
          price: this.getRealPrice(product.price, product.discount),
          quantity: quantity,
        };

        this.cart = [...this.cart, cartItem];
      }
    },

    getRealPrice(price, discount) {
      return price * (discount / 100);
    },

    removeFromCart(id) {
      this.cart = this.cart.filter((item) => item.id !== id);
    },

    openCarouselModal(visibleIndex) {
      if (window.window.screen.width < 1024) return;

      this.imageClickedIndex = visibleIndex;
      this.carouselModalOpen = true;
    },
  },
};
</script>

<style>
html {
  font-family: "Nunito", sans-serif;
  font-size: var(--fs-400);

}

body {
  background-color: var(--app-container);
  color: var(--color-black);
}
.container {
  margin: 0rem 2rem;
}
@media only screen and (max-width: 600px) {
  .container {

    margin: 0rem 0.5rem;

  }

}


</style>
