<template>
  <article class="product-info container">
    <h6 class="product-info__producer-name">{{ product.name }}</h6>
    <AppButton
      v-if="product.verified == 1"
      class="app-button--green-box-shadow"
      icon="checkmark.svg"
      disabled
      alt="Cart"
      @click="addToCart"
      >Sản phẩm đã được kiểm chứng và xác thực bởi 1Gate</AppButton
    >
    <AppButton
      v-else
      class="app-button--orange-box-shadow"
      icon="warning.svg"
      disabled
      alt="Cart"
      @click="addToCart"
    >
      Sản phẩm đã vượt quá số lượt quét quy định. Vui lòng cân nhắc kỹ trước khi
      mua!</AppButton
    >
    <div class="product-info__buttons-container">
      <QuantityInput :quantity="product" />
    </div>
    <h4 class="product-info__name">Thông tin sản phẩm</h4>
    <div class="product-info__d-flex">
      <div class="product-info__d-flex_left">
        <p class="product-info__description">
          <span class="product-info__description_left">Tên sản phẩm: </span>
          <span class="color-blue">{{ product.name }}</span>
        </p>
        <p class="product-info__description">
          <span class="product-info__description_left">Mã sản phẩm: </span>
          <span class=""> {{ product.code }}</span>
        </p>
        <p class="product-info__description">
          <span class="product-info__description_left">Giá niêm yết: </span>
          <span class=""> {{ product.price }}đ</span>
        </p>

        <p class="product-info__description d-flex">
          <span class="product-info__description_left ">Xuất xứ: </span>
          <span class="d-flex gap-1">
            <img
              class="japan-qrcode__icon"
              :src="getImgUrl(product.flags)"
              :alt="alt"
            />
           {{product.org}}</span
          >
        </p>
      </div>
      
    </div>

    <h4 class="product-info__name_cc">Thông tin nhà cung cấp</h4>
    <div class="product-info__d-flex">
      <div class="product-info__d-flex_left">
        <p class="product-info__description">
          <span class="product-info__description_left">Đơn vị nhập khẩu: </span>
          <span class="color-blue">{{ product.importer }}</span>
        </p>    
        <p class="product-info__description">
          <span class="product-info__description_left">Đơn vị phân phối: </span>
          <span class="color-blue">{{ product.importer }}</span>
        </p>     
      </div>
     
    </div>
  </article>
</template>

<script>
import QuantityInput from "@/components/QuantityInput";
import AppButton from "@/components/AppButton";

export default {
  components: { QuantityInput, AppButton },
  props: {
    product: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["addToCart"],
  data() {
    return {
      quantity: 0,
    };
  },
  computed: {
    productRealPrice() {
      return this.product.price * (this.product.discount / 100);
    },
  },
  methods: {
    getImgUrl(pic) {
      return require(`../assets/images/${pic}`);
    },
    addQuantity() {
      this.quantity++;
    },
    removeQuantity() {
      if (this.quantity >= 1) this.quantity--;
    },
    addToCart() {
      this.$emit("addToCart", this.product, this.quantity);

      this.quantity = 0;
    },
  },
};
</script>

<style scoped>
.bold {
  font-weight: 600;
}



.japan-qrcode__icon {
  width: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .product-info {
    margin-top: 0;
  }

  .product-info__producer-name {
    margin: 0;
  }
}

.product-info {
  display: grid;
}

.product-info__producer-name {
  text-transform: uppercase;
  font-size: var(--fs-300);
  font-weight: 700;
  color: var(--color-primary);
  margin: 0.5rem;
}

.product-info__name {
  font-size: var(--fs-600);
  font-weight: 700;
  margin-top: 1.2rem;
  margin-bottom: 0.5rem;
}

.product-info__name_cc{
  font-size: var(--fs-600);
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.product-info__description {
  color: var(--color-dark-grayish-blue);
  margin-bottom: 0.5rem;
  font-size: 0.89em;
}

.product-info__d-flex {
  display: flex;
  justify-self: start;
  gap: 30px;
}

.product-info__price-container {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
}

.product-info__current-price-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.product-info__current-price {
  font-size: var(--fs-600);
  font-weight: 700;
}

.product-info__discount {
  color: var(--color-primary-orange);
  padding: 0.3rem 0.6rem;
  border-radius: var(--border-radius-size);
  font-weight: 700;
  background-color: var(--color-primary-pale-orange);
}

.product-info__old-price {
  color: var(--color-grayish-blue);
  font-weight: 700;
  text-decoration: line-through;
}

.product-info__buttons-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;
}

@media (min-width: 1024px) {
  .product-info {
    max-width: 40%;
  }

  .product-info__price-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-info__buttons-container {
    flex-direction: row;
    gap: 1.3rem;
  }
}
</style>
