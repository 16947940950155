import "../src/styles/colors.css";
import "../src/styles/variables.css";
import "../src/styles/reset.css";
import "../src/styles/utilities.css";

import { createApp } from "vue";
import Product from "./Product.vue";
import Home from "./Home.vue";
import App from "./App.vue";

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { path: "/1gate-check/:id", name: "Product", component: Product },
  { path: "/", name: "Home", component: Home },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(router).mount("#app");
