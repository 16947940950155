<template>
  <article class="product--home container">
    <div class="search-wrapper">
      <input v-model="epc" class="search-input" type="text" placeholder="Nhập EPC thẻ RFID"
        @keyup.enter="onHandlerSearch">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor"
        stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="feather feather-search"
        viewBox="0 0 24 24" @click="onHandlerSearch">
        <defs></defs>
        <circle cx="11" cy="11" r="8"></circle>
        <path d="M21 21l-4.35-4.35"></path>
      </svg>
    </div>
    <p v-if="noData" class=" noData text-center">Rất tiếc không tìm thấy thông tin sản phẩm</p>
    <div  class="product-list view ">
     

      <ProductList v-if="epc && product && product.length != 0 && !isLoading" :product="product" />



    </div>
  </article>
  <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
</template>

<script>
import ProductList from "./components/ProductList.vue"
import json from "./list.json";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
export default {
  components: {
    ProductList,
    Loading
  },
  data() {
    return {
      epc: "",
      product: null,
      json,
      noData:false,
      isLoading: false,
      fullPage: true,
     

    }
  },
  mounted() {
  },
  methods: {
    onHandlerSearch() {
      this.isLoading = true
      setTimeout(() => {
        this.product = json.find(e => e.RFID == this.epc) ? json.find(e => e.RFID == this.epc) : []
        this.product.length == 0 ? this.noData = true : this.noData = false;
        this.isLoading = false
      }, 500);


    }
  }


}
</script>

<style>
.product--home {
  height: calc(100vh - 4rem);
}

.view {
  padding-top: 20px;
  flex-direction: row;
  display: grid;
  gap: 12px;

}

@media (min-width: 792px) {
  .view {

    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }

}

.noData{
  padding-top:1rem ;
}

.product--home {
  padding: 8.5rem 0.5rem 0.5rem 0.5rem;
}

.search-wrapper {
  border-radius: 20px;
  background-color: var(--search-area-bg);
  padding-right: 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 480px;
  color: var(--light-font);
  box-shadow: 0 2px 6px 0 rgb(136 148 171 / 20%), 0 24px 20px -24px rgb(71 82 107 / 10%);
  overflow: hidden;
  margin: auto;
}

.search-input {
  border: none;
  flex: 1;
  outline: none;
  height: 100%;
  padding: 0 20px;
  font-size: 16px;
  background-color: var(--search-area-bg);
  color: var(--main-color);
}
</style>