<template>
  <div class="quantity-input">
    <div class="quantity-input__qrcode">
      <div class="quantity-input__div">
        <img class="quantity-input__qrcode__icon" :src="getImgUrl('qrcode.svg')" />
        <p class="quantity-input__qrcode__text">
          Số lượt quét QRcode: {{ quantity.QRCount }}
        </p>
      </div>
    </div>
    <div class="quantity-input__rfid">
      <div class="quantity-input__div border-left">
        <img class="quantity-input__qrcode__icon" :src="getImgUrl('rfid-icon.svg')" />
        <p class="quantity-input__qrcode__text">
          Số lượt quét RFID: {{ quantity.RFIDCount }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quantity: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["addQuantity", "removeQuantity"],
  methods: {
    getImgUrl(pic) {
      return require(`../assets/images/${pic}`);
    },
  },
};
</script>

<style scoped>
.quantity-input {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.5rem;
  background-color: var(--color-light-grayish-blue);
  border-radius: var(--border-radius-size);
  width: 100%;
  padding: 0.8rem  0.3rem 0.7rem 0.3rem;
  border: 1px solid;
  border-radius: 10px;

}

/* @media (min-width: 1024px) {
  .quantity-input {
    width: 40%;
  }
} */

.quantity-input__qrcode__icon {
  width: 28px;
  height: 28px;
  margin-left: auto;
  margin-right: auto;

}

.quantity-input__div {
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;


}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.384);
}

.quantity-input__qrcode__text {
  color: var(--color-dark-grayish-blue);
  font-size: 0.8rem;
  margin-top: 0.8rem;

}
</style>
