<template>
  <div v-if="product" class="product-item" @click="handleRouter(product.code)">
    <div :class="product.verified ? 'product-image-success' : 'product-image-false'">
      <img class="image" :src="getImgUrl(product.mainThumbnail)">
    </div>
    <div class="product-detail">
      <div class="product-name">
        <div class="main-name ">Tên sản phẩm: <span class="color-blue">{{ product.name }}</span></div>
      </div>
      <div class="product-price">
        <div class="price-valuta">Mã sản phẩm: {{ product.code }}</div>
      </div>
      <div class="product-price">
        <div class="price-valuta">Giá niêm yết: <span class="color-price">{{ product.price }}đ</span></div>
      </div>
      <div class="product-price">
        <div class="price-valuta d-flex">Xuất xứ: <span class="d-flex gap-1"><img class="japan-qrcode__icon"
              :src="getImgUrlIcon(product.flags)" />
            {{ product.org }}</span></div>
      </div>
      <div class="product-price">
        <div class="price-valuta  d-flex">Xác thực: <span class="color-price d-flex xac-thuc"><img class="app__icon"
              :src="getImgUrlIcon(product.verified ? 'checkmark-s.svg'
              : 'warning-s.svg')" :alt="alt" />{{ product.verified ? 'Đã xác thực' : 'Chưa xác thực'
  }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    if (this.product) {
      console.log(this.product)

    }
  },
  methods: {
    handleRouter(id) {
      this.$router.push({ name: 'Product', params: { 'id': id } });
    },
    getImgUrl(pic) {
      return require(`../assets/images/product/${pic}`);
    },
    getImgUrlIcon(pic) {
      return require(`../assets/images/${pic}`);
    },
  }
}
</script>

<style>
.xac-thuc {
  margin-left: 10px;
  gap: 10px;
}

.product-item {
  border-top: 1px solid #DADADA;
  padding: 20px 0;
  display: flex;
  gap: 10px;
  background-color: var(--search-area-bg);
  padding: 9.5px;
  border-radius: 10px;
  font-size: 0.8rem;
  line-height: 1.5rem;
}
.image{
  width: 100%;
  border-radius: 8px;

}
.japan-qrcode__icon {
  margin-left: 0.4rem;
  width: 1.5rem;
}

.product-image-success {
  -o-object-fit: cover;
  object-fit: cover;
  width: 40%;
  position: relative;
  text-align: center;
}

.product-image-false {
  -o-object-fit: cover;
  object-fit: cover;
  width: 40%;
  position: relative;
  text-align: center;
}


.product-item .product-detail {
  display: flex;
  flex-direction: column;
  width: 60%;
}

@media (min-width: 792px) {
  .product-item .product-detail {

    gap: 0.7rem;

  }

}
</style>